.fade-in-left {
    opacity: 0;
    transform: translateX(-100px); /* Initial position off-screen to the left */
    transition: all 0.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s;
  }
  
  .visible {
    opacity: 1;
    transform: translateX(0); /* Move to original position */
  }
  
  .top-middle {
    position: relative;
    top: 0px; /* Adjust this value as needed */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* Ensure it's above other content */
    color: transparent; /* Initial color set to transparent */
    background-image: linear-gradient(to right, indigo, red); /* Gradient background */
    background-clip: text; /* Clip the background to the text */
    -webkit-background-clip: text; /* For Safari/Chrome */
    -webkit-text-fill-color: transparent; /* Hide the text color */
    animation: fillIn 5s forwards infinite; 
    text-align: center;
  }
  
  .top-middle ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    justify-content: center; /* Center vertically */
    align-items: center;
  }
  
  .top-middle li {
    display: flex;
    margin: 0 10px; /* Adjust spacing between list items */
    font-size: 18px; /* Adjust font size as needed */
  }

  #polishText {
    font-family: 'Zebulon', sans-serif;
    font-size: 24px;
    letter-spacing: 5px;
  }
  
  #eyewearText {
    font-family: 'Mundo', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .navlogo {
    height: 48px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  @media (min-width: 768px) {
    .top-middle {
      display: none;
    }
  }
  