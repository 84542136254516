/* AboutUs.css */

body {
    font-family: 'Raleway', sans-serif; /* Apply Lora font family to the entire page */
}

.gradient-bgg {
    background-image: linear-gradient(135deg, #AAA492, #FFFFFF, #AAA492);
    background-size: 200% 200%;
    animation: gradientAnimation 10s ease infinite;
  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }


.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 100;
    opacity: 0; /* Set initial opacity to 0 */
    animation: fadeIn 1s forwards; /* Apply fade-in animation */
}

.about-section {
    text-align: center;
    margin-bottom: 30px; /* Add margin to separate sections */
}

.about-section h1 {
    font-size: 36px;
    font-family: 'Raleway', sans-serif;
    color: #333;
    margin-top: 50px;
    margin-bottom: 20px; /* Add margin below heading */
}

.about-section p {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    color: #666;
    margin-bottom: 15px;
    line-height: 2.5; /* Add line height for better readability */
}

.vision-section {
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    color: black; 
    opacity: 0; /* Set initial opacity to 0 */
    animation: fadeIn 1s forwards; /* Apply fade-in animation */
}

.vision-section h2 {
    font-size: 24px;
    color: #333;
    line-height: 2.5;
    margin-bottom: 20px;
}

.vision-section p {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    color: #666;
    margin-bottom: 15px;
    line-height: 2.5; /* Add line height for better readability */
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .about-container {
        padding: 10px;
    }
    .about-section h1 {
        margin-top: 0;
    }
}