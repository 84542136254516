/* Reset CSS */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.gradient-bg {
  background-image: linear-gradient(135deg, #AAA492, #FFFFFF, #AAA492);
  background-size: 200% 200%;
  animation: gradientAnimation 10s ease infinite;
  
  
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Rest of your existing CSS styles */


.powered-by-logo {
  height: 14px; /* Adjust the height of the image */
  width: auto; /* Allow the width to adjust proportionally */
}

.polish-logo {
  margin-bottom: -50px; /* Adjust margin as needed */
}

.polish-logo img {
  width: 150px; /* Adjust width as needed */
  height: 100%; /* Maintain aspect ratio */
}

/* Body Container */
.body-container {
  font-family: 'Poppins', sans-serif;
}

/* Footer Section */
#footer {
  background-color: #f7f7f7;
  padding: 50px 0;
  padding-bottom: 20px;
  padding-left: 10vh;
  padding-right: 10vh;
  
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  
}

.footer-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 84px;
}

.footer-column {
  width: fit-content;
  padding: 0 15px;
  margin-bottom: 30px;
 
}

.footer-column h2 {
  color: #333;
  font-size: 20px;
  margin-bottom: 20px;
}

.footer-column p {
  color: #666;
  line-height: 1.6;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column a {
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-column a:hover {
  color: #333;
}

/* Add hover effect on the Useful Links */
.footer-column ul li a:hover {
  color: #007bff; /* Change to desired hover color */
}

/* Social Icons */
.social-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.social-icons a {
  display: inline-block;
  margin-right: 10px;
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
  background-color: transparent; /* Set background color to transparent */
  border: none; /* Remove border */
}

.social-icons a:hover {
  color: #333;
}

.social-icons i {
  margin-right: 5px;
}

ul {
  list-style: none;
}

.example-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-1 .icon-content {
  margin: 0 10px;
  position: relative;
}

.example-1 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}

.example-1 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.example-1 .icon-content .link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px; /* Adjust width to match SVG icon width */
  height: 35px; /* Adjust height to match SVG icon height */
  border-radius: 50%; /* Make it fully rounded */
  color: #4d4d4d;
  transition: all 0.3s ease-in-out;
}

.example-1 .icon-content .link:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}

.example-1 .icon-content .link svg {
  width: 50px;
  height: 40px;
}

.example-1 .icon-content .link[data-social="spotify"]:hover {
  color: #1db954;
}

.example-1 .icon-content .link[data-social="pinterest"]:hover {
  color: #bd081c;
}

.example-1 .icon-content .link[data-social="dribbble"]:hover {
  color: #ea4c89;
}

.example-1 .icon-content .link[data-social="telegram"]:hover {
  color: #0088cc;
}


/* Footer.css */

.google-play-badge {
  margin-top: 20px; /* Adjust margin as needed */
  display: inline-block; /* Ensure inline-block for transitions */
  transition: transform 0.3s ease; /* Add transition effect */
}

.google-play-badge img {
  width: 200px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}

.google-play-badge:hover {
  transform: scale(1.1); /* Zoom effect on hover */
}

/* CSS for centering the copyright text */
.copyright-text {
  text-align: center;
  margin-top: 10px; /* Adjust margin-top as needed */
  color: #5c5c5cad;
}

.highlight-link {
  color: rgba(17, 17, 17, 0.829); /* Set the font color to black */
  font-size: 19px; /* Reduce the font size */
  font-weight: 700; /* Increase font thickness */
  text-decoration: none; /* Remove underline */
}

@media (max-width: 786px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .polish-logo {
    order: -1;
    margin-bottom: 30px;
    text-align: center;
  }

  .footer-content {
    width: 100%;
    display: flex;
    text-align: center;
    gap: 5px;
  }

  .footer-column {
    width: max-content;
    padding: 0 15px;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-column h2 {
    font-size: 16px;
  }

  .footer-column p {
    font-size: 14px;
  }

  .google-play-badge {
    display: flex;
    justify-content: center;
  }

  .google-play-badge img {
    width: 150px;
    text-align: center;
  }

  .polish-logo img {
    width: 100px;
  }

  .copyright-text {
    margin-bottom: 32px;
    font-size: 10px;
  }

  .highlight-link {
    font-size: 12px;
  }

  .powered-by-logo {
    height: 8px; /* Adjust the height of the image */
    width: auto; /* Allow the width to adjust proportionally */
  }

  /* Adjustments for sections stacking vertically */
  /* Remove display: block; for .footer-column */
  
  /* Centering the "Contact Us" section */
  .footer-column[title="Contact Us"],
  /* Centering the "Useful Links" section */
  .footer-column[title="Useful Links"],
  /* Centering the "Follow Us" section */
  .footer-column[title="Follow Us"] {
    display: block;
    margin-bottom: 20px;
  }

  /* Adjustments for social media icons */
  .social-icons {
    justify-content: center;
  }

  .social-icons a {
    margin-right: 10px;
  }

  .social-icons i {
    margin-right: 5px;
  }
}


/* Footer.css */

/* Footer.css */

.about-us-column {
  width: 50%; /* Adjust width as needed */
  padding: 0 15px;
  margin-bottom: 30px;
}

.about-us-column h2 {
  color: #333;
  font-size: 20px;
  margin-bottom: 20px;
}



.about-us-column p {
  color: #666;
  line-height: 1.6;
  /* font-style: italic;  */
}


/* Adjustments for mobile responsiveness */
@media (max-width: 786px) {
  .about-us-column {
    width: 100%; /* Change to full width on smaller screens */
    margin-bottom: 20px;
    text-align: center;
  }
}
