/* CategoryCatalogue.js */

.title-container {
  padding-bottom: 25px; 
  padding-top: 20px;
  position: relative;
  text-align: center;
}

.animated-title{
  font-family: "Merriweather", serif;
  font-weight: bolder;
  font-style: normal;
  font-size: 2.8em;
  color: #546e7a;
}

.catalogue-container {
  width: 100%; /* Make the container full-width */
  max-width: 1200px; /* Limit the maximum width */
  margin: 20px auto; /* Center the container horizontally */
  padding: 0 20px; /* Adjust padding */
  justify-content: center; /* Center the content horizontally */
}

.catalogue-row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: space-around; /* Center items horizontally with gaps */
  gap: 30px; /* Increase gap between items */
}

.catalogue-col-1 {
  flex: 0 0 25%;
  max-width: 25%;
  /* padding: 0px 10px; */
}
  
.catalogue-col-2 {
  flex: 0 0 75%;
  max-width: 75%;
  /* padding: 0 10px; */
}

/* CategoryList.js */

.category-list-container {
  cursor: pointer;
  position: absolute;
  margin-left: -108px;
  margin-top: 0px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #ccc; /* Add border */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  
}

.category-list-container:hover {
  border-color: #0c0c0c86;
}


.category-list {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  margin: 0;
  background-color: transparent;
}

.category-header{
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 10px;
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.category-list li {
  cursor: pointer;
  padding: 10px 20px; /* Increase padding */
  border-radius: 10px; /* Adjust border radius */
  background-color: transparent;
  border: none;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.category-list li.active {
  background-color: #546e7a;
  color: #fff;
}

.category-list li:hover {
  background-color: #546e7a;
  color: #ffffff;
}

.category-list li.active {
  color: #ffffff; /* Optionally change text color to white for better contrast */
  font-weight: bolder;
  font-size: 18px;
}


/* ProductList.js */

.product-list {
  padding: 10px;
  margin-top: 30px;
  margin-left: -220px;
  margin-right: -220px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   border: 2px solid #ccc;/* Add border */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
 
  
}

.product-list:hover {
  border-color: #0c0c0c86;
}

.card-info-container {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0 -5px;
}
 
.card-info-container ul {
  list-style-type: none;
  padding: 0;
}

.product-list li {
  background-color: #fff; /* Background color */
  border: 1px solid #ddd;
  border-radius: 10px; /* Adjust border radius */
  padding: 10px; /* Adjust padding */
  aspect-ratio: 1 / 1;
  width: calc(25% - 20px);
  position: relative; /* Position the overlay */
  overflow: hidden; /* Hide overflow content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  transition: transform 0.3s, box-shadow 0.3s; /* Add transition */
  margin: 0 10px 20px; /* Margin around each product item */
  text-align: center; 
}

.product-item {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease; /* Add transition for the transform property */
}

.product-item:hover {
  transform: scale(1.03); /* Scale up the product item on hover */
}

.product-item img {
  aspect-ratio: 1 / 1; /* Apply aspect ratio of 1:1 */
  max-width: 100%; /* Ensure image does not overflow */
  border-radius: 8px; /* Adjust border radius */
  object-fit: contain; /* Ensure image covers the container */
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.product-item:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.product-item p {
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
  font-size: 16px; /* Adjust font size */
  margin-top: 10px; /* Adjust margin */
}




/* Responsive styles for screens with a maximum width of 325px */

@media (max-width: 320px) {

  .title-container {
    z-index: 0;
    position: relative; /* Add this line */
    text-align: center; /* Add this line */
  }

  .animated-title {
    font-size: 1.8em; /* Further adjusted font size */
    line-height: 1.2;
    display: inline-block;
    width: 100%; /* Add this line */
    text-align: center;
  }

  .catalogue-row {
    justify-content: center; 
    align-items: center;
    text-align: center;
  }

  .catalogue-col-1, .catalogue-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px;
  }

  .category-list-container {
    margin-top: -10px;
    margin-left: auto; /* Auto margin for centering */
    margin-right: auto; /* Auto margin for centering */
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
  }

  .category-list {
    display: flex;
    flex-direction: column;
    padding-left: 0; /* Remove left padding */
  }

  .category-list li {
    width: 100%;
    padding: 10px 0; /* Adjust padding */
  }

  .category-header {
    display: none; /* Hide the category header in small screens */
  }
  
  .product-list {
    margin-top: 200px;
    margin-left: 0px; /* Adjust margin for smaller screens */
    margin-right: 0px; /* Adjust margin for smaller screens */
    justify-content: center;
  }

  .product-list li {
    width: calc(100% - 20px); /* Full width with 20px margin on both sides for a single product in a row */
    margin: 0 10px 20px; /* Margin around each product item */
    justify-content: center;
  }

  .product-item img {
    width: 100%; /* Set the width to 100% */
    height: auto; /* Allow the height to adjust automatically */
    border-radius: 0; /* Remove border radius */
    object-fit: cover; /* Ensure the image covers the container */
    justify-content: center;
  }

  .overlay {
    display: none;
  }

}

@media (min-width: 321px) and (max-width: 375px) {
  
  .title-container {
    z-index: 0;
    position: relative; /* Add this line */
    text-align: center; /* Add this line */
  }

  .animated-title {
    font-size: 2.1em; /* Further adjusted font size */
    line-height: 1.2;
    display: inline-block;
    width: 100%; /* Add this line */
    text-align: center;
  }

  .catalogue-row {
    justify-content: center; 
  }

  .catalogue-col-1, .catalogue-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px;
  }

  .category-list-container {
    margin-top: -60px !important;
  }

}

@media (min-width: 376px) and (max-width: 425px) {
  
  .title-container {
    z-index: 0;
    position: relative; /* Add this line */
    text-align: center; /* Add this line */
  }

  .animated-title {
    font-size: 2.3em; /* Further adjusted font size */
    line-height: 1.2;
    display: inline-block;
    width: 100%; /* Add this line */
    text-align: center;
  }

  .catalogue-row {
    justify-content: center; 
  }

  .catalogue-col-1, .catalogue-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px;
  }

  .category-list-container {
    margin-top: 150px;
    margin-left: auto; /* Adjust margin for smaller screens */
    width: 100%; /* Adjust width for smaller screens */
    border-radius: 0; /* Remove border radius */
    box-shadow: none; /* Remove box shadow */
    border: none; /* Remove border */
  }

  .category-list {
    display: flex;
    flex-direction: column; /* Change direction to column */
    text-align: center; /* Center text */
    padding-left: 0; /* Remove left padding */
  }

  .category-list li {
    width: 100%; /* Adjust width to fit content */
    padding: 10px 0; /* Adjust padding */
    font-size: 16px;
    font-weight: bold;
  }

  .category-header {
    display: none; /* Hide the category header in small screens */
  }

  .product-list {
    margin-top: 200px; /* Adjust margin for smaller screens */
    margin-left: -5px; /* Adjust margin for smaller screens */
    margin-right: -5px; /* Adjust margin for smaller screens */
  }

  .product-list li {
    width: calc(100% - 20px); /* Full width with 20px margin on both sides for a single product in a row */
    margin: 0 10px 20px; /* Margin around each product item */
    justify-content: center;
  }

  .product-item img {
    margin-left: 0; /* Reset left margin */
    justify-content: center;
  }
}

/* @media (width: 430px){
  .category-list-container {
    margin-left: -60px !important;
    width: 50% !important;
  }
} */

@media (width: 540px){
  .category-list-container {
    margin-left: -90px !important;
    width: 50% !important;
  }
}

@media (max-width: 576px) {

  .title-container {
    z-index: 0;
    position: relative; /* Add this line */
    text-align: center; /* Add this line */
  }

  .animated-title {
    font-size: 2.5em; /* Further adjusted font size */
    line-height: 1.2;
    display: inline-block;
    width: 100%; /* Add this line */
    text-align: center;
  }

  .category-list-container {
    margin-top: 10px !important;
    margin-left: 10px; /* Adjust margin for smaller screens */
    width: 80%; /* Adjust width for smaller screens */
    border-radius: 0; /* Remove border radius */
    box-shadow: none; /* Remove box shadow */
    border: none; /* Remove border */
    background-color: transparent;
  }

  .category-list {
    display: flex;
    flex-direction: column; /* Change direction to column */
    text-align: center; /* Center text */
    padding-left: 0; /* Remove left padding */
  }

  .category-list li {
    width: 100%; /* Adjust width to fit content */
    padding: 10px 0; /* Adjust padding */
  }

  .category-header {
    display: none; /* Hide the category header in small screens */
  }

  .product-list {
    margin-top: 200px;
    margin-left: -10px; /* Adjust margin for smaller screens */
    margin-right: -10px; /* Adjust margin for smaller screens */
  }

  .product-list li {
    width: calc(100% - 20px); /* Full width with 20px margin on both sides for a single product in a row */
    margin: 0 10px 20px; /* Margin around each product item */
    justify-content: center;
  }
  
  .product-item img {
    width: 100%; /* Set the width to 100% */
    height: auto; /* Allow the height to adjust automatically */
    border-radius: 0; /* Remove border radius */
    object-fit: cover; /* Ensure the image covers the container */
    
    justify-content: center;
  }

  .overlay {
    display: none;
  }
}

@media (min-width: 426px) and (max-width: 576px){
  .category-list-container{
    margin-left: -100px !important;
    width: 60% !important;
  }
}

@media (min-width: 577px /*426px*/) and (max-width: 768px){

  .title-container {
    z-index: 0;
    position: relative; /* Add this line */
    text-align: center; /* Add this line */
  }

  .animated-title {
    font-size: 2.5em; /* Further adjusted font size */
    line-height: 1.2;
    display: inline-block;
    width: 100%; /* Add this line */
    text-align: center;
  }

  .catalogue-row {
    justify-content: center; 
  }

  .catalogue-col-1,
  .catalogue-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px; /* Add horizontal padding */
    margin-bottom: 20px; /* Add bottom margin */
  } 

  .category-list-container {
    margin-top: -20px;
    margin-left: 150px;
    margin-right: auto; /* Auto margin for centering */
    width: 50%;
    border-radius: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
  }

  .category-list {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center;
    padding-left: 0; /* Remove left padding */
  }

  .category-list li {
    width: 100%;
    padding: 10px 0; /* Adjust padding */
  }

  .category-header {
    display: none; /* Hide the category header in small screens */
  }

  .product-list {
    margin-top: 200px;
    margin-left: 0px; /* Adjust margin for smaller screens */
    margin-right: 0px; /* Adjust margin for smaller screens */
    justify-content: center;
  }

  .product-list li {
    width: calc(50% - 20px); /* Half width with 20px margin on both sides for two products in a row */
    margin: 0 10px 20px; /* Margin around each product item */
    justify-content: center;
  }

  .product-item img {
    width: 100%; /* Set the width to 100% */
    height: auto; /* Allow the height to adjust automatically */
    border-radius: 0; /* Remove border radius */
    object-fit: cover; /* Ensure the image covers the container */
    justify-content: center;
  }

  .overlay {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {


  .catalogue-col-1,
  .catalogue-col-2 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 10px; /* Add horizontal padding */
    margin-bottom: 20px; /* Add bottom margin */
  }

  .category-list-container {
    margin-left: -250px; /* Auto margin for centering */
    margin-right: auto; /* Auto margin for centering */
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: none;
    justify-content: center;
  }

  .category-list {
    /* display: flex;
    flex-direction: column; */
    justify-content: center; 
    align-items: center; 
    text-align: center;
    padding-left: 0; 
  }

  /* .category-list li {
    width: 100%;
    padding: 10px 0; 
  } */

  .category-header {
    display: none; /* Hide the category header in small screens */
  }

  .product-list {
    margin-top: 20px;
    margin-left: -160px; /* Adjust margin for smaller screens */
    margin-right: -180px; /* Adjust margin for smaller screens */
    justify-content: center;
  }

  .product-list li {
    width: calc(33.33% - 20px); /* One-third width with 20px margin on both sides for three products in a row */
    margin: 0 10px 20px; /* Margin around each product item */
    justify-content: center;
  }

  .product-item img {
    width: 100%; /* Set the width to 100% */
    height: auto; /* Allow the height to adjust automatically */
    border-radius: 0; /* Remove border radius */
    object-fit: cover; /* Ensure the image covers the container */
    justify-content: center;
  }

  .overlay {
    display: none;
  }
}

