.navbar__logo img {
  width: 110px;
}

.navbar__links {
  list-style-type: none;
  display: flex;
  font-family: 'Poppins', sans-serif;
}

/*  */
.navbar__links li {
  margin-right: 24px;
  font-family: 'Marcellus', serif;
  /* Adjust spacing between links */
}

.navbar__links li:last-child {
  margin-right: 0;
}

.navbar__links a {
  color: rgb(48, 48, 48);
  text-decoration: none;
  position: relative;
  font-family: 'marcellus', serif;
  font-weight: 525;
}

.navbar__links a::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background-color: rgb(255, 153, 0);
  position: absolute;
  bottom: -2px;
  left: 0;
  transition: width 0.3s ease, left 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.navbar__links a:hover::after {
  width: 100%;
  left: 0;
  opacity: 1;
  transition-delay: 0.3s;
  /* Delay the transition */
}

.navbar__links li a.active {
  font-weight: bold;
  color: #076142;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background-color: white;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar__left {
  display: flex;
  align-items: center;
}

.navbar__logo {
  width: 40px;
  height: auto;
  margin-left: 10px;
}

.navbar__links {
  list-style: none;
  display: flex;
}

.navbar__links li {
  margin-right: 20px;
}

.navbar__links a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

/* .navbar__links a:hover {
  color: #aa4465;
  border-bottom: 2px solid #aa4465;
  font-weight: bold;
} */

.navbar__right {
  display: flex;
  align-items: center;
}

.navbar__profile {
  position: relative;
  cursor: pointer;
}

.navbar__profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.navbar__profile-img:hover {
  background-color: rgb(255, 208, 208);
}

.navbar__dropdown {
  position: absolute;
  top: 40px;
  right: 1px;
  background-color: white;
  list-style: none;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar__dropdown li {
  margin-bottom: 5px;
}

.navbar__dropdown a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.navbar__dropdown a:hover {
  color: #aa4465;
  font-weight: bold;
}

.navbar__dropdown li a {
  display: flex;
  align-items: center;
}

.navbar__dropdown li a img {
  margin-right: 5px;
}

/* dots__dropdown */
.dots__profile {
  position: relative;
  cursor: pointer;
}

.dots__profile-img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.dots__profile-img:hover {
  background-color: rgb(255, 208, 208);
}

.dots__dropdown {
  position: absolute;
  top: 40px;
  left: 25px;
  background-color: white;
  list-style: none;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dots__dropdown li {
  margin-bottom: 5px;
}

.dots__dropdown a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.dots__dropdown a:hover {
  color: #aa4465;
  font-weight: bold;
}

.dots__dropdown li a {
  display: flex;
  align-items: center;
}

.dots__dropdown li a img {
  margin-right: 5px;
}

.navbar__timer-dropdown {
  margin-right: 10px;
}

.navbar__timer-dropdown select {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  margin-right: 20px;
}

.navbar__timer {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

@media screen and (max-width: 786px) {

  .navbar{
    width: 100%;
  }

  .navbar__logo img {
    width: 100px;
  }

  .navbar__links {
    display: none;
  }


  .dots__dropdown {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    list-style: none;
    padding: 10px;
    border-radius: 5px;
    display: none; /* Initially hide the dropdown */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 2;
    line-height: 200%;
    margin-right: 20px;
  }
  
  .dots__profile:hover .dots__dropdown {
    display: flex; /* Display the dropdown when hovering over the profile icon */
    flex-direction: column;
    margin-left: -15px;
  }

}
